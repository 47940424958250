import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import "../components/main.css"
import Container from '../components/container'
import Header from '../components/header'
import PrimaryContent from '../components/primary'
import Pillars from "../components/pillars"
import Cards from "../components/cards"
import Footer from "../components/footer"

export default function RootIndex({data: { contentfulHome }}) {
  return (
    <Container>
      <Helmet title={contentfulHome.headerTitle} />
      <Header data={contentfulHome} />
      <PrimaryContent data={contentfulHome} />
      <Pillars data={contentfulHome} />
      <Cards data={contentfulHome} />
      <Footer data={contentfulHome} />
    </Container>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHome(contentful_id: {eq: "3PzDi1qSAKMC94baofp5p1"}) {
      id
      ...HeaderInformation
      ...MainInformation
      ...PillarsInformation
      ...CardInformation
      ...FooterInformation
    }
  }
`
