import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export default function Image({id}) {
  const {allContentfulAsset : { nodes }} = useStaticQuery(graphql`
    query AssetQuery {
      allContentfulAsset {
        nodes {
          contentful_id
          description
          file {
            url
          }
        }
      }
    }
  `)
  const imageData = nodes.find(node => node.contentful_id === id);
  return (
    <div className="author">
      <div className="image">
        <img src={imageData.file.url} alt={imageData.description} />
      </div>
      <p className="credit">- <strong>{imageData.description}</strong></p>
    </div>
  )
}

