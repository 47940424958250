import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES } from '@contentful/rich-text-types';
import merge from 'lodash/merge'
import options from "./rich-text-options"

const richTextOptions = merge({
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => <a href="https://www.trinitycollege.lk/trinity150/" className="button-link">{children}</a>
  }
}, options)

function FooterSection({content}) {
  return (
    <section>{ documentToReactComponents(content.json, options) }</section>
  )
}

function MediaLinks({links}) {
  return (
    <section>
      <h4>Media and Links</h4>

      <ul className="plain">
        { links.map(linkData => <li key={`icon-${linkData.text}`}><a href={linkData.link}><i className={`icon ${linkData.icon}`}>{' '}</i>{linkData.text}</a></li>) }
      </ul>
    </section>
  )
}

function FooterNotes({content}) {
  return (
    <div className="inner">
      <div className="content" style={{justifyContent :"center"}}>
        <header className="special" style={{marginBottom: 0}}>
          { documentToReactComponents(content.json, richTextOptions) }
        </header>
      </div>
    </div>
  );
}

export default function Footer({data}) {
  return (
    <footer id="footer" style={{padding: "4rem 0 4rem 0"}}>
      <div className="inner">
        <div className="content">
          <FooterSection content={data.leftFooter} />
          <FooterSection content={data.centerFooter} />
          <MediaLinks links={data.mediaAndLinks.links} />
        </div>
      </div>
      <FooterNotes content={data.footNote} />
    </footer>
  )
}


export const query = graphql`
  fragment FooterInformation on ContentfulHome {
    leftFooter {
      json
    }
    centerFooter {
      json
    }
    mediaAndLinks {
      links {
        link
        text
        icon
      }
    }
    footNote {
      json
    }
  }
`
