import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from "./rich-text-options"

export default function Pillars({data}) {
  return (
    <>
      <section id="cta" className="wrapper">
        <div className="inner">
        { documentToReactComponents(data.threePillarsContent.json, options) }
        </div>
      </section>
    </>
  )
}


export const query = graphql`
  fragment PillarsInformation on ContentfulHome {
    threePillarsContent {
      json
    }
  }
`
