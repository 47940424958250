import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>
const Italic = ({ children }) => <i>{children}</i>
const BlockQuote = ({ children }) => <blockquote>{children}</blockquote>

export default {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [MARKS.ITALIC]: (node, children) => <Italic>{children}</Italic>,
    [BLOCKS.BLOCKQUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  }
}