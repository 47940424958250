import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from "./rich-text-options"

export default function Header({data}) {
  return (
    <section id="banner">
      <div className="inner">
        <h1>{ data.headerTitle }</h1>
        { documentToReactComponents(data.headerContent.json, options) }
        <br /><br />
        <a href="#donate" className="button">Donate</a>
      </div>
    </section>
  )
}


export const query = graphql`
  fragment HeaderInformation on ContentfulHome {
    headerTitle
    headerContent {
      json
    }
  }
`
