import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from '@contentful/rich-text-types';
import merge from 'lodash/merge'
import options from "./rich-text-options"
import Image from "./image";
// import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const H3 = ({children}) => <header><h3>{children}</h3></header>

const richTextOptions = merge({
  renderNode: {
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image id={node.data.target.sys.contentful_id} />
  }
}, options)

function Main({mainTitle, mainContent}) {
  return (
    <header className="special">
      <h2>{ mainTitle }</h2>
      { documentToReactComponents(mainContent.json, richTextOptions) }
    </header>
  )
}

function TestimonialContent({content}) {
  return (
    <section>
      <div className="content">
      { documentToReactComponents(content.json, richTextOptions) }
      </div>
    </section>
  )
}

function Testimonials({data}) {
  return (
    <div className="testimonials">
      <TestimonialContent content={data.testimonialLeft} />
      <TestimonialContent content={data.testimonialCenter} />
      <TestimonialContent content={data.testimonialRight} />
    </div>
  )
}

function Donations({content}) {
  const [showModal, setShowModal] = useState(false);
  const onPledge = (event) => {
    event.preventDefault();
    setShowModal(true);
    return false;
  };
  return (
    <>
      <div id="donate" className="inner">

        <header className="special" style={{marginBottom: "2rem"}}>
          <h2>Donations</h2>
          { documentToReactComponents(content.json, richTextOptions) }
        </header>
        
        <div style={{textAlign: "center", width: "100%"}}>
          {/* <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ZDXMSZZXA4ULU&source=url" style={{backgroundColor: "orange"}} className="button" target="_blank">Donate Now</a>
          { " "}
          <a href="#donate" className="button" target="_blank" style={{backgroundColor: "orange"}} onClick={onPledge}>Pledge</a> */}

          {/* donorbox */}
          <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
          <iframe src="https://donorbox.org/embed/trinity-150" title="Donation" height="685px" width="100%" style={{maxWidth: "500px", minWidth: "310px", maxHeight: "none!important"}} seamless="seamless" name="donorbox" frameBorder="0" scrolling="no" allowpaymentrequest="true"></iframe>
        </div>
        
      </div>

      <div style={{textAlign: "center", width: "100%"}}>
        <a href="https://forms.gle/pWQVdjGtj76aHMKn8" className="button" target="_blank" style={{backgroundColor: "orange"}}>Pledge</a>
      </div>

      {/* <ReactModal 
        isOpen={showModal}
        contentLabel="Pledge Form"
      >
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScS6ftpRj8Zej2VtiwW6SdgxgsOnjOM1by9bXTxey1-4PbHwA/viewform?embedded=true" width="640" height="1260" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </ReactModal> */}
      
    </>
  )
}

export default function Primary({data}) {
  return (
    <section className="wrapper">
      <div className="inner">
        <Main mainTitle={data.mainTitle} mainContent={data.mainContent}  />
        <Testimonials data={data} />
      </div>
      
      <Donations content={data.donationsContent} />

    </section>
  )
}


export const query = graphql`
  fragment MainInformation on ContentfulHome {
    mainContent {
      json
    }
    donationsContent {
      json
    }
    testimonialLeft {
      json
    }
    testimonialCenter {
      json
    }
    testimonialRight {
      json
    }
  }
`
